@font-face {
    font-family: 'diodrum';
    src: url('./Diodrum-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppins';
    src: url('./Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('./LatoLatin-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}